import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper.js";
import { TaskPool } from "core/TaskPool.js";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import TabsComponent from "views/Components/TabsComponentPagination";
import { ActionCodes, AddressType, BankingAccountTypes, ClientType, CurrencyType, IdentificationMethod, LicenseRegistrationEntity, MenuCodes } from "views/Constants/Constant.js";
import ClientSummary from "./ClientSteps/ClientSummary.jsx";
import ContractDetails from "./ClientSteps/ContractDetails";
import CustomerDetails from "./ClientSteps/CustomerDetails.jsx";
import Memo from "./ClientSteps/Memo";
import RiskRatingTabs from "./ClientSteps/RiskRatingTabs";
import Security from "./ClientSteps/Security";
import Settlement from "./ClientSteps/Settlement.jsx";
import ResourceHelper from "../../../core/ResourceHelper";

const initialState = {
	vModel: {},
	paramGlobalModel: {},
	paramCustomerProfile: {},
	paramBankCustomerCorporate: {},
	paramBankCustomerKeyContactList: [],
	paramBankCustomerDirectorsList: [],
	paramBankCustomerRegulatoryLicenseList: [],
	paramBankCustomerContractList: [],
	paramBankCustomerAddressList: [],
	paramBankCustomerMemoList: [],
	paramBankCustomerInternalAccountList: [],
	paramBankCustomerExternalAccountList: [],
	paramBankCustomerCorporateAuthOwnerList: [],
	paramBankCustomerCorporateAuthPersonList: [],
	paramIdentificationMethot1: {},
	paramIdentificationMethot2: {},
	paramCustomerMobilePhone: {},
	paramCustomerPrimaryPhone: {},
	paramCorporateBussinesPhone: {},
	paramCorporateFaxPhone: {},
	paramCustomerAlternativePhone: {},
	paramBankCustomerSecurityList: [],
	ParentTabIndex: 0,
	ChildTabIndex: 0,
	IsClientComponent: false,
	alert: null,
	isLoading: false,
	isSalesPerson: false,
	CustomDisabled: null
};

class ClientSetup extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;


		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.ParameterClientTypeC = { ParameterCode: "ClientType", ParameterValue2: "C" };
		this.ParameterClientTypeSA = { ParameterCode: "ClientType", ParameterValue: "SAP", ParameterValue2: "SA" };
		this.ParameterClientSearchCS = {
			UniqueClientId: 0,
			ClientTypes: [ClientType.Personal, ClientType.Corporate]
		};
		this.ParameterClientSearchSA = {
			UniqueClientId: 0,
			ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate]
		};
		this.ParameterSortName = { Member: "Name" };
		this.ParameterSortUniqueClientId = { Member: "UniqueClientId" };
		this.ParameterUniqueClientId = { UniqueClientId: 0 };

		this.ClientMemoTypeId = 0;
		this.RiskRatingMemoTypeId = 0;
		this.emptyObject = {};
	}
	RenderClientNameSelect = (d) => {
		return d.Name + (d.IsProfileCompleted ? "" : " *") + " (" + d.ClientType.ParameterDesc + ")"+"*"+"("+ d.OrganizationUniqueId+")" ;
	}
	RenderUniqueClientSelect = (d) => {
		return d.UniqueClientId + (d.IsProfileCompleted ? "" : " *") + " (" + d.ClientType.ParameterDesc + ")"+"*"+"("+ d.OrganizationUniqueId+")" ;;
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}
	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	reset() {
		this.setState(initialState);
	}
	RenderOrganizationUniqueIdSelect = (d) => {
		if(window.OrganizationUniqueId != 5000)
		{
			const model = { ...this.state.paramGlobalModel };
			model["OrganizationUniqueId"] = d.OrganizationUniqueId;
			this.setState({ paramGlobalModel: model });
		}
			 
		return (d.OrganizationUniqueId +"-"+d.OrganizationName) ;
	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetup) {
				this.props.setAppCenterTitle("Client");
			} else if (this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) {
				this.props.setAppCenterTitle("Sales Agent");
				if (this.props.setAppLeftTitle)
					this.props.setAppLeftTitle("Sales Agent Setup");
			} else if (this.props.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring) {
				this.props.setAppCenterTitle("SALES PERSON");
				if (this.props.setAppLeftTitle)
					this.props.setAppLeftTitle("Sales Person Client Monitor");
			} else if (this.props.MenuCode == MenuCodes.CallCenterClientMonitor) {
				this.props.setAppCenterTitle("CALL CENTER");
				if (this.props.setAppLeftTitle)
					this.props.setAppLeftTitle("Client Monitor");
			}
		}

		const code = this.props.MenuCode;
		const model = { ...this.state.paramGlobalModel };
		model.MenuCode = code;
		if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetup || this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) {

		} else {
			model.IsExceptClientSetup = true;
		}


		this.setState({ paramGlobalModel: model, isSalesPerson: ClientHelper.IsSalesPerson() });
		this.FetchClientLimitedAuthority(MenuCodes.CSLimitedClientSearch, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
		this.GetMemoTypeIds();
	}
	ClearAllCustomerData = () => {
		this.setState(initialState);
		this.hideAlert();
		const model = { ...this.state.paramGlobalModel };
		//model.CheckNewClient = true;
		model.UniqueClientId = "";
		model.CustomerName = "";
		model.Id = 0;
		model.ClientType = "";
		model.OrganizationUniqueId=0;
		this.setState({ paramGlobalModel: model });
	}
	FetchClientLimitedAuthority = (MenuCode, ActionCode, ResultFunc) => {
		if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetup || this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) {
			return;
		} else {
			ResultFunc(true);
		}

		const user = ClientHelper.GetUser();

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/coreapi/v1.0/MenuActionRight/GetByMenuIdAndActionId",
				{
					"MenuCode": MenuCode,
					"ActionCode": ActionCode
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						ResultFunc(false);
					}
					var rightList = responseData.Item;
					if (rightList != null) {
						var y = rightList.RoleIds.indexOf(user.RoleId);
						ResultFunc(y == -1);
					} else {
						ResultFunc(false);
					}
				},
				error => {
					ResultFunc(false);
				}
			));
	}

	GetMemoTypeIds = () => {
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/coreapi/v1.0/Parameter/Search",
				{
					ParameterCode: "MemoType"
				},
				responseData => {
					if (responseData.IsSucceeded && responseData.Item != null) {
						var clientMemo = responseData.Item.filter(x => x.ParameterValue == "Client")[0];
						var riskRatingMemo = responseData.Item.filter(x => x.ParameterValue == "RiskRating")[0];
						this.ClientMemoTypeId = clientMemo ? clientMemo.Id : 0;
						this.RiskRatingMemoTypeId = riskRatingMemo ? riskRatingMemo.Id : 0;
					}
				},
				error => {
					this.ShowMessage("error", "Error", error);
				})
		);
	}

	// HandleChange
	handleChangeGlobal = (name, newValue, data) => {
		if (name == "CheckNewClient" && newValue == true) {
			this.showClearMeesage("This will erase the information you entered on the screen. Are you sure you want to quit this transaction ?");
			const model = { ...this.state.paramGlobalModel };
			model[name] = newValue;
			this.setState({ paramGlobalModel: model });
		} else {
			const model = { ...this.state.paramGlobalModel };
			model[name] = newValue;

			if (name == "ClientType") {
				model["ClientTypeId"] = data && data.Id;
			}

			this.setState({ paramGlobalModel: model });
		}
		if (name == "Id" && data != null) {
			const model = { ...this.state.paramGlobalModel };
			model.UniqueClientId = data.UniqueClientId;
			model.CustomerName = data.Name;
			model.Id = data.Id;
			model.ClientType = data.ClientType.ParameterValue;
			model.ClientTypeId = data.ClientType.Id;
			model.OrganizationUniqueId = data.OrganizationUniqueId;
			this.setState({ paramGlobalModel: model }, () => {
				this.GetAllCustomerModels();
			});
		} else if (name == "Id" && data == null) {
			this.ClearAllCustomerData();
		}
	}
	handleChangePersonal = (name, newValue, data) => {
		const model = { ...this.state.paramCustomerProfile };
		model[name] = newValue;
		this.setState({ paramCustomerProfile: model });
	}
	handleChangeCorporate = (name, newValue, data) => {
		const model = { ...this.state.paramBankCustomerCorporate };
		if (name == "LicenseRegistrationEntityId" && data != null) {
			if (data.ParameterValue == LicenseRegistrationEntity.Other) {
				model.LicenseRegistrationEntityOther = "";
			} else {
				model.LicenseRegistrationEntityOther = null;
			}
		}
		if (name == "LicenseRegistrationEntityId2" && data != null) {
			if (data.ParameterValue == LicenseRegistrationEntity.Other) {
				model.LicenseRegistrationEntityOther2 = "";
			} else {
				model.LicenseRegistrationEntityOther2 = null;
			}
		}

		if (name == "LicenseRegistrationEntityId3" && data != null) {
			if (data.ParameterValue == LicenseRegistrationEntity.Other) {
				model.LicenseRegistrationEntityOther3 = "";
			} else {
				model.LicenseRegistrationEntityOther3 = null;
			}
		}
		model[name] = newValue;
		this.setState({ paramBankCustomerCorporate: model });
	}
	handleChangeIdentification1 = (name, value, data) => {
		const model = { ...this.state.paramIdentificationMethot1 };
		if (model.Id == null) {
			model.Id = 0;
			model.UniqueClientId = this.state.paramGlobalModel.Id;
		}
		if (name == "IdentificationMethodId" && data != null) {
			if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
				model.IsIdVerification = true;
			} else {
				model.IsIdVerification = false;
			}
		}
		if (name == "IdentityTypeId") {
			model.IdentityTypeIdStr = data && data.ParameterValue;
			model.IdentityNumber = null;
			model.IdentityTypeParameterValue2 = data && data.ParameterValue2;
			model.ProofOfResidenceId = null;
		}
		model[name] = value;
		this.setState({ paramIdentificationMethot1: model });

		const identification = { ...this.state.paramCustomerProfile.CustomerIdentification1 };
		identification[name] = value;
		this.handleChangePersonal("CustomerIdentification1", identification);
	}
	handleChangeIdentification2 = (name, value, data) => {
		const model = { ...this.state.paramIdentificationMethot2 };
		if (model.Id == null) {
			model.Id = 0;
			model.UniqueClientId = this.state.paramGlobalModel.Id;
		}
		if (name == "IdentificationMethodId" && data != null) {
			if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
				model.IsIdVerification = true;
			} else {
				model.IsIdVerification = false;
			}
		}
		if (name == "IdentityTypeId") {
			model.IdentityTypeIdStr = data && data.ParameterValue;
			model.IdentityNumber = null;
			model.IdentityTypeParameterValue2 = data && data.ParameterValue2;
			model.ProofOfResidenceId = null;
		}
		model[name] = value;
		this.setState({ paramIdentificationMethot2: model });

		const identification = { ...this.state.paramCustomerProfile.CustomerIdentification2 };
		identification[name] = value;
		this.handleChangePersonal("CustomerIdentification2", identification);

	}
	handleChangePhone1 = (name, value) => {
		const phone = { ...this.state.paramCustomerPrimaryPhone };
		phone[name] = value;
		this.setState({ paramCustomerPrimaryPhone: phone });

		const phoneModel = { ...this.state.paramCustomerProfile.PrimaryPhone };
		phoneModel[name] = value;
		this.handleChangePersonal("PrimaryPhone", phoneModel);
	}
	handleChangePhone2 = (name, value) => {
		const phone = { ...this.state.paramCustomerAlternativePhone };
		phone[name] = value;
		this.setState({ paramCustomerAlternativePhone: phone });

		const phoneModel = { ...this.state.paramCustomerProfile.AlternativePhone };
		phoneModel[name] = value;
		this.handleChangePersonal("AlternativePhone", phoneModel);
	}
	handleChangePhone3 = (name, value) => {
		const phone = { ...this.state.paramCustomerMobilePhone };
		phone[name] = value;
		this.setState({ paramCustomerMobilePhone: phone });

		const phoneModel = { ...this.state.paramCustomerProfile.MobilePhone };
		phoneModel[name] = value;
		this.handleChangePersonal("MobilePhone", phoneModel);
	}
	handleChangeCorporatePhoneFax = (name, value) => {
		const phone = { ...this.state.paramCorporateFaxPhone };
		phone[name] = value;
		this.setState({ paramCorporateFaxPhone: phone });

		const phoneModel = { ...this.state.paramBankCustomerCorporate.FaxPhone };
		phoneModel[name] = value;
		this.handleChangeCorporate("FaxPhone", phoneModel);
	}
	handleChangeCorporatePhoneBuss = (name, value) => {
		const phone = { ...this.state.paramCorporateBussinesPhone };
		phone[name] = value;
		this.setState({ paramCorporateBussinesPhone: phone });

		const phoneModel = { ...this.state.paramBankCustomerCorporate.BusinessPhone };
		phoneModel[name] = value;
		this.handleChangeCorporate("BusinessPhone", phoneModel);
	}
	// HandleChange

	setCustomerState = (data) => {
		this.setState({ IsClientComponent: !this.state.IsClientComponent });
		if (data != null) {
			var temp1 = data.CustomerIdentification1;
			if (temp1 != null) {
				temp1.IdentityExpireDate = temp1.IdentityExpireDate ? DateHelper.ToMoment(temp1.IdentityExpireDate) : null;
				if (temp1.IdentityType != null && temp1.IdentityType.ParameterValue != null) {
					temp1.IdentityTypeIdStr = temp1.IdentityType.ParameterValue;
					temp1.IdentityTypeParameterValue2 = temp1.IdentityType.ParameterValue2;
				}
			}
			var temp2 = data.CustomerIdentification2;
			if (temp2 != null) {
				temp2.IdentityExpireDate = temp2.IdentityExpireDate ? DateHelper.ToMoment(temp2.IdentityExpireDate) : null;
				if (temp2.IdentityType != null && temp2.IdentityType.ParameterValue != null) {
					temp2.IdentityTypeIdStr = temp2.IdentityType.ParameterValue;
					temp2.IdentityTypeParameterValue2 = temp2.IdentityType.ParameterValue2;
				}
			}

			data.BirthDate = data.BirthDate ? DateHelper.ToMoment(data.BirthDate) : null;

			this.setState({
				paramCustomerProfile: data || {},
				paramCustomerMobilePhone: data.MobilePhone || {},
				paramCustomerPrimaryPhone: data.PrimaryPhone || {},
				paramCustomerAlternativePhone: data.AlternativePhone || {},
				paramIdentificationMethot1: temp1 || {},
				paramIdentificationMethot2: temp2 || {}
			});

			const model = { ...this.state.paramGlobalModel };
			model.UniqueClientId = data.UniqueClient.UniqueClientId;
			model.Id = data.UniqueClientId;
			model.ClientType = data.ClientType;
			model.CustomerName = data.Name;
			this.setState({ paramGlobalModel: model });
		} else {
			this.setState({
				paramCustomerProfile: {},
				paramCustomerMobilePhone: {},
				paramCustomerPrimaryPhone: {},
				paramCustomerAlternativePhone: {},
				paramIdentificationMethot1: {},
				paramIdentificationMethot2: {}
			});
		}

	}
	setCorporateState = (data) => {
		this.setState({ IsClientComponent: !this.state.IsClientComponent });

		if (data != null) {
			this.setState({
				paramBankCustomerCorporate: data || {},
				paramCorporateBussinesPhone: data.BusinessPhone || {},
				paramCorporateFaxPhone: data.FaxPhone || {}
			});

			const model = { ...this.state.paramGlobalModel };
			model.UniqueClientId = data.UniqueClient.UniqueClientId;
			model.Id = data.UniqueClientId;
			model.ClientType = data.ClientType;
			model.CustomerName = data.LegalName;
			this.setState({ paramGlobalModel: model });

		} else {
			this.setState({
				paramBankCustomerCorporate: {},
				paramCorporateBussinesPhone: {},
				paramCorporateFaxPhone: {}
			});
		}
	}
	setIdentificationState = (data) => {
		if (data != null) {
			this.setState({ paramIdentificationMethot1: data[0], paramIdentificationMethot2: data[1] });
		}
	}
	ValidateClientSubmit = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const global = { ...this.state.paramGlobalModel };
		if (global == null || global.Id == null || global.Id == 0) {
			vModel.Id = true;
			ErrorList.push("Unique Client ID must be defined !");
		} else { vModel.Id = false; }
		if (global == null || global.UniqueClientId == null || global.UniqueClientId == 0) {
			vModel.UniqueClientId = true;
			ErrorList.push("Unique Client number must be defined !");
		} else { vModel.UniqueClientId = false; }
		if (global != null && global.UniqueClientId != null && global.UniqueClientId.toString().length != 6) {
			ErrorList.push("UniqueClientID must be valid ! (Length is 6)");
		}
		if (global.ClientType == null || global.ClientType == "") {
			ErrorList.push("Client type must be defined !");
		}
		if (this.state.paramBankCustomerContractList != null && this.state.paramBankCustomerContractList.length < 1) {
			ErrorList.push("You Must Add Contract To Complete Profile !");
		}
		if (this.state.paramBankCustomerAddressList != null && this.state.paramBankCustomerAddressList.length < 1) {
			ErrorList.push("You Must Add Address To Complete Profile !");
		}
		if (this.state.paramBankCustomerInternalAccountList != null && this.state.paramBankCustomerInternalAccountList.length < 1) {
			ErrorList.push(`You Must Add ${window.Title} Wallet To Complete Profile !`);
		}
		if (global.OrganizationUniqueId == null || global.OrganizationUniqueId == "") {
			ErrorList.push("Organization   must be defined !");
		}
		if (global != null && (global.ClientType == ClientType.Corporate || global.ClientType == ClientType.SalesAgentCorporate)) {
			if (this.state.paramBankCustomerKeyContactList != null && this.state.paramBankCustomerKeyContactList.length < 1) {
				ErrorList.push("You Must Add Key Contact To Complete Profile !");
			}
			if (this.state.paramBankCustomerCorporateAuthOwnerList != null && this.state.paramBankCustomerCorporateAuthOwnerList.length < 1) {
				ErrorList.push("You Must Add Beneficiary OwnerTo Complete Profile !");
			}
			if (this.state.paramBankCustomerCorporateAuthPersonList != null && this.state.paramBankCustomerCorporateAuthPersonList.length < 1) {
				ErrorList.push("You Must Add Authorized Signing Officer To Complete Profile !");
			}
		}

		this.state.paramBankCustomerContractList.forEach(element => {
			const account = this.state.paramBankCustomerInternalAccountList.filter(x =>
				x.ProgramId == element.ProgramId &&
				x.BankingAccountType != null &&
				x.BankingAccountType.ParameterValue == BankingAccountTypes.PrimaryAccount &&
				x.Currency != null && x.Currency.Code == CurrencyType.CAD);
			if (account.length == 0) {
				ErrorList.push("Funding CAD wallet must be defined for " + element.Program.Description);
			}
		});

		var mainAdress = this.state.paramBankCustomerAddressList.filter(x => x.AddressType != null && x.AddressType.ParameterValue == AddressType.MainAddress);
		if (mainAdress.length == 0) {
			ErrorList.push("Main address must be defined !");
		}


		if (global == null || global.Id == null || !global.Id > 0) {
			ErrorList.push("Client Id must be defined!");
		}
		if (global.ClientType == null || global.ClientType == "") {
			ErrorList.push("Client type must be defined !");
		}
			if (global.OrganizationUniqueId == null || global.OrganizationUniqueId == "") {
			ErrorList.push("OrganizationUniqueId  must be defined !");
		}
		if (global.ClientType == ClientType.Corporate || global.ClientType == ClientType.SalesAgentCorporate) {
			var corp = { ...this.state.paramBankCustomerCorporate };
			if (corp.LegalName == null || corp.LegalName == "") {
				ErrorList.push("Client Legal Name definition not found !");
			}
		}

		if (global.ClientType == ClientType.Personal || global.ClientType == ClientType.SalesAgentPersonal) {
			var indv = { ...this.state.paramCustomerProfile };
			if (indv.Name == null || indv.Name == "") {
				ErrorList.push("Client name must be defined !");
			}
			if (indv.SurName == null || indv.SurName == "") {
				ErrorList.push("Client lastname must be defined !");
			}
			if ((indv.BirthDate == null || indv.BirthDate == "") &&
				!(global.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (global.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && global.ClientType == ClientType.SalesAgentPersonal))) {
				ErrorList.push("Client birth date must be defined !");
			}
		}

		if (this.state.paramBankCustomerAddressList == null || this.state.paramBankCustomerAddressList.length == 0) {
			ErrorList.push("Client address must be defined !");
		}

		var main = this.state.paramBankCustomerAddressList.find(x => x.AddressType != null && x.AddressType.ParameterValue == AddressType.MainAddress);
		if (main == null) {
			ErrorList.push("Client main address must be defined !");
		}
		if (main == null || main.Address1 == null || main.Location == null || main.ZipCode == null || main.CountryId == null || main.ProvinceId == null || main.CityId == null) {
			ErrorList.push("Client main address fields must be defined !");
		}
		if (main == null || main.Address1 == "" || main.Location == "" || main.ZipCode == "" || main.CountryId == "" || main.ProvinceId == "" || main.CityId == "") {
			ErrorList.push("Client main address fields must be defined !");
		}

		if (global.ClientType == ClientType.Personal || global.ClientType == ClientType.SalesAgentPersonal) {
			var identification = { ...this.state.paramIdentificationMethot1 };
			if (identification == null || identification.IdentityTypeId == 0 || identification.IdentityTypeId == "") {
				ErrorList.push("Client identity type fields must be defined !");
			};
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});

	}
	GenerateCompleteProfileRequest = () => {
		var global = { ...this.state.paramGlobalModel };
		var request = {};
		if (global == null || global.Id == null || !global.Id > 0) {
			return null;
		}
		if (global.ClientType == null || global.ClientType == "") {
			return null;
		}

		request.Id = global.Id;
		request.UniqueClientId = global.UniqueClientId;
		request.ClientType = global.ClientType;
		request.OrganizationUniqueId = global.OrganizationUniqueId;
		if (global.ClientType == ClientType.Corporate) {
			var corp = { ...this.state.paramBankCustomerCorporate };
			if (corp.LegalName == null || corp.LegalName == "") {
				return null;
			}
			request.LegalName = corp.LegalName;
		}

		if (global.ClientType == ClientType.Personal || global.ClientType == ClientType.SalesAgentPersonal) {
			var indv = { ...this.state.paramCustomerProfile };
			if (indv.Name == null || indv.Name == "") {
				return;
			}
			if (indv.SurName == null || indv.SurName == "") {
				return;
			}
			if ((indv.BirthDate == null || indv.BirthDate == "") && global.ClientType != ClientType.SalesAgentPersonal) {
				return;
			}
			request.FirstName = indv.Name;
			request.LastName = indv.SurName;
			if (global.ClientType != ClientType.SalesAgentPersonal)
				request.BirthDate = indv.BirthDate;
			request.FullName = `${indv.Name} ${indv.SurName}`;
		}

		if (this.state.paramBankCustomerAddressList == null || this.state.paramBankCustomerAddressList.length == 0) {
			return null;
		}

		var main = this.state.paramBankCustomerAddressList.find(x => x.AddressType != null && x.AddressType.ParameterValue == AddressType.MainAddress);
		if (main == null) {
			return null;
		}
		if (main.Address1 == null || main.Location == null || main.ZipCode == null || main.CountryId == null || main.ProvinceId == null || main.CityId == null) {
			return null;
		}
		if (main.Address1 == "" || main.Location == "" || main.ZipCode == "" || main.CountryId == "" || main.ProvinceId == "" || main.CityId == "") {
			return null;
		}

		request.Address1 = main.Address1;
		request.Address2 = main.Address2;
		request.Location = main.Location;
		request.ZipCode = main.ZipCode;
		request.CityId = main.CityId;
		request.ProvinceId = main.ProvinceId;
		request.CountryId = main.CountryId;


		request.IdentityTypeId = 0;
		if (global.ClientType == ClientType.Personal) {
			var identification = { ...this.state.paramIdentificationMethot1 };
			if (identification == null || identification.IdentityTypeId == 0 || identification.IdentityTypeId == "") {
				return null;
			}
			request.IdentityTypeId = identification.IdentityTypeId;
			request.ProofOfResidenceId = identification.ProofOfResidenceId;
		}

		request.ClientTypeId = global.ClientTypeId;

		return request;
	}
	CompleteClientProfile = (workflowId, after) => {
		var global = { ...this.state.paramGlobalModel };
		if (global != null && global.Id != null && global.Id > 0) {

			var request = this.GenerateCompleteProfileRequest();
			if (request == null) {
				this.props.showMessage("error", "Error", "Some Client information are missing for submit operation.");
				return;
			}
			var res = this.ValidateClientSubmit();
			if (res != true) {
				return;
			}
			this.loadTaskPool.AppendTask(
				Proxy.POST(
					"/bankapi/v1.0/BankCustomer/CompleteProfile",
					request,
					responseData => {
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						if (responseData.Item == true) {
							this.ShowMessageConfirm("success", "Success", "Client Profile Marked As Completed !", () => {
								this.PerformProfileCompleted();
								if (after) after();
							});
						}
					},
					error => {

						this.ShowMessage("error", "Error", error);
					},
					workflowId
				));
		}
	}

	PerformProfileCompleted = () => {
		this.hideAlert();

		this.ClearAllCustomerData();
	}
	showClearMeesage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Clear" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.ClearAllCustomerDataCancel()} OnConfirm={() => this.ClearAllCustomerData()} />
		});

	}

	ClearAllCustomerDataCancel = () => {
		const model = { ...this.state.paramGlobalModel };
		model.CheckNewClient = false;
		this.setState({ paramGlobalModel: model });
		this.hideAlert();
	}
	// ---------- Customer Models   ---------------
	GetAllCustomerModels = () => {
		const model = { ...this.state.paramGlobalModel };

		if (model.Id != null && model.Id != 0) {
			const UniqueClientId = model.Id;
			if (model.ClientType == ClientType.Personal || model.ClientType == ClientType.SalesAgentPersonal) {
				this.GetCustomerProfile(UniqueClientId);
			} else if (model.ClientType == ClientType.Corporate || model.ClientType == ClientType.SalesAgentCorporate) {
				this.GetCorporateProfile(UniqueClientId);
			} else {
				this.ShowMessage("error", "Error", "Client Type Not Valid !");
				return;
			}
			this.GetCustomerAddressModels(UniqueClientId);
			this.GetCustomerContractModels(UniqueClientId);
			this.GetCustomerDirectorsModels(UniqueClientId);
			this.GetCustomerKeyContactModels(UniqueClientId);
			this.GetCustomerRegulatoryLicenses(UniqueClientId);
			this.GetCustomerMemoModels(UniqueClientId);
			this.GetCustomerSecurityModels(UniqueClientId);
			this.GetCustomerExternalBankAccountModels(UniqueClientId);
			this.GetCustomerDCBankAccountModels(UniqueClientId);
			this.GetCustomerCorporateAuthOwnerModels(UniqueClientId);
			this.GetCustomerCorporateAuthPersonModels(UniqueClientId);
		}
	}

	GetAllCustomerModelsPreLoad = (Id, ClientTypeStr) => {
		if (Id != 0 && Id != null) {

			if (ClientTypeStr == ClientType.Personal || ClientTypeStr == ClientType.SalesAgentPersonal) {
				this.GetCustomerProfile(Id);
			} else if (ClientTypeStr == ClientType.Corporate || ClientTypeStr == ClientType.SalesAgentCorporate) {
				this.GetCorporateProfile(Id);
			} else {
				console.log("GetAllCustomerModelsPreLoad ClientType not valid !");
				return;
			}

			this.GetCustomerAddressModels(Id);
			this.GetCustomerContractModels(Id);
			this.GetCustomerKeyContactModels(Id);
			this.GetCustomerDirectorsModels(Id);
			this.GetCustomerRegulatoryLicenses(Id);
			this.GetCustomerMemoModels(Id);
			this.GetCustomerSecurityModels(Id);
			this.GetCustomerExternalBankAccountModels(Id);
			this.GetCustomerDCBankAccountModels(Id);
			this.GetCustomerCorporateAuthOwnerModels(Id);
			this.GetCustomerCorporateAuthPersonModels(Id);
		} else {
			console.log("GetAllCustomerModelsPreLoad Id null");
		}
	}

	GetCustomerProfile = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerProfile ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/BankCustomer/GetById/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerProfile ->", responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setCustomerState(responseData.Item);

				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));

	}
	GetCorporateProfile = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCorporateProfile ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerCorporate/GetCorporateById/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCorporateProfile ->", responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setCorporateState(responseData.Item);

				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerAddressModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerAddressModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		} this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerAddress/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerAddressModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerAddressList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerAddressList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerContractModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerContractModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerContract/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerContractModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						responseData.Item.forEach( item => {
							if (item.hasOwnProperty("Program") && item.Program.hasOwnProperty("Description")) {
								item.Program.Description = ResourceHelper.CorrectAccountNames(item.Program.Description);
							}
						})
						this.setState({ paramBankCustomerContractList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerContractList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerKeyContactModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerKeyContactModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerKeyContact/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerKeyContactModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerKeyContactList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerKeyContactList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}

	GetCustomerDirectorsModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerDirectorsModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/Director/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerDirectorsModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerDirectorsList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerDirectorsList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}

	GetCustomerRegulatoryLicenses = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerRegulatoryLicenseModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerRegulatoryLicense/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerRegulatoryLicenseModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerRegulatoryLicenseList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerRegulatoryLicenseList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerMemoModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerMemoModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerMemo/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerMemoModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerMemoList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerMemoList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerSecurityModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerSecurityModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerSecurity/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerSecurityModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerSecurityList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerSecurityList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerExternalBankAccountModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerExternalBankAccountModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerSettlementAccount/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerExternalBankAccountModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerExternalAccountList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerExternalAccountList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));

	}
	GetCustomerDCBankAccountModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerDCBankAccountModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/Account/GetByCustomerIdForClientSetup/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerDCBankAccountModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						responseData.Item.forEach( item => {
							if (item.hasOwnProperty("Program") && item.Program.hasOwnProperty("Description"))
								item.Program.Description = ResourceHelper.CorrectAccountNames(item.Program.Description);
							if (item.hasOwnProperty("BankingAccountType") && item.BankingAccountType.hasOwnProperty("ParameterDesc"))
								item.BankingAccountType.ParameterDesc = ResourceHelper.CorrectAccountNames(item.BankingAccountType.ParameterDesc);
							if (item.hasOwnProperty("AccountName"))
								item.AccountName = ResourceHelper.CorrectAccountNames(item.AccountName);
							if (item.hasOwnProperty("IntendUseOfAccount") && item.IntendUseOfAccount.hasOwnProperty("ParameterDesc"))
								item.IntendUseOfAccount.ParameterDesc = ResourceHelper.CorrectAccountNames(item.IntendUseOfAccount.ParameterDesc);
						})
						this.setState({ paramBankCustomerInternalAccountList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerInternalAccountList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerCorporateAuthOwnerModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerCorporateAuthOwnerModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerCorporateAuthOwner/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerCorporateAuthOwnerModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerCorporateAuthOwnerList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerCorporateAuthOwnerList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetCustomerCorporateAuthPersonModels = (UniqueClientId) => {
		if (UniqueClientId == null || UniqueClientId == 0) {
			const global = { ...this.state.paramGlobalModel };
			if (global.Id == null || global.Id == 0) {
				console.log("GetCustomerCorporateAuthPersonModels ->", "UniqueClientId NULL");
				return;
			} else {
				UniqueClientId = global.Id;
			}
		}
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/CustomerCorporateAuthPerson/GetByCustomerId/Id?Id=" + UniqueClientId,
				responseData => {
					console.log("GetCustomerCorporateAuthPersonModels ->", responseData.IsSucceeded);

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.setState({ paramBankCustomerCorporateAuthPersonList: responseData.Item || [] });
					} else {
						this.setState({ paramBankCustomerCorporateAuthPersonList: [] });
					}
				},
				error => {

					this.ShowMessage("error", "Error", error);
				}
			));
	}
	handleChangeParentTab = (event, active, tab) => {
		if (!tab || !this.state.paramGlobalModel.CheckNewClient) {
			this.setState({ ParentTabIndex: active });
		}
	}
	handleChangeChildTab = (event, active, tab) => {
		if (!tab || !this.state.paramGlobalModel.CheckNewClient) {
			this.setState({ ChildTabIndex: active });
		}
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});

	}
	ShowMessageConfirm = (type, title, message, confirm) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={confirm} />
		});

	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { Disabled } = this.props;
		const { paramGlobalModel, CustomDisabled, vModel, isSalesPerson } = this.state;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} TaskPoolDomain={this.loadTaskPool} />

				<GridItem xs={12}>
					<ButtonToolbar ButtonList={[
						{
							Code: "Clear",
							OnClick: () => this.showClearMeesage("This will erase the information you entered on the screen. Are you sure you want to quit this transaction ?"), Disabled: paramGlobalModel.IsExceptClientSetup || Disabled
						},
						{
							Code: "Submit",
							RowId: this.state.paramGlobalModel.Id,
							OnClick: this.CompleteClientProfile,
							Disabled: paramGlobalModel.IsExceptClientSetup || Disabled || paramGlobalModel.Id == null ? true : paramGlobalModel.Id == 0 ? true : false,
							ModelFunction: () => this.GenerateCompleteProfileRequest(),
							FillDataFromModel: model => {
								this.GetAllCustomerModelsPreLoad(model.Id, model.ClientType);
							},
							ValidationFunction: this.ValidateClientSubmit
						}
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					<Card className="no-radius">
						<CardBody>
							<GridContainer spacing={16}>

								{/* Client Type */}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_ClientSetup) && (
									<GridItem xs={3}>
										<GenericSelectInput
											Name="ClientType"
											LabelText="Client Type"
											Value={paramGlobalModel == null ? "" : paramGlobalModel.ClientType || ""}
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="ParameterValue"
											TextValueMember="ParameterDesc"
											Method="POST"
											TaskPoolDomain={this.loadTaskPool}
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterClientTypeC}
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.Id == 0 ? Disabled : true}
											DataRoot="Item"
											Required
											IsInvalid={vModel.ClientType}

										/>
									</GridItem>
								)}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_ClientSetup ) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Disabled={CustomDisabled ||  Disabled}
											key={this.state.IsClientComponent}
											Name="OrganizationUniqueId"
											LabelText="Organization"
											Value={paramGlobalModel.OrganizationUniqueId || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="OrganizationUniqueId"
											 TextValueMember="OrganizationUniqueId"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/OrganizationInfo/GetAll"
											Method="POST"
											RenderItem={this.RenderOrganizationUniqueIdSelect}
											Parameter={this.emptyObject}
											Required
											IsInvalid={vModel.OrganizationUniqueId}
										/>
									</GridItem>
								)}
								
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) && (
									<GridItem xs={3}>
										<GenericSelectInput
											Name="ClientType"
											LabelText="Sales Agent Type"
											Value={paramGlobalModel == null ? "" : paramGlobalModel.ClientType || ""}
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="ParameterValue"
											TextValueMember="ParameterDesc"
											Method="POST"
											TaskPoolDomain={this.loadTaskPool}
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterClientTypeSA}
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.Id == 0 ? Disabled : true}
											DataRoot="Item"
											Required
											IsInvalid={vModel.ClientType}
										/>
									</GridItem>
								)}
								{/* Client Type */}


								{/* Client List  */}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_ClientSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.CheckNewClient || Disabled}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Client Name"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="Name"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/SearchForBackOffice"
											Method="POST"
											RenderItem={this.RenderClientNameSelect}
											Parameter={this.ParameterClientSearchCS}
											Required
											IsInvalid={vModel.Id}
										/>
									</GridItem>
								)}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_ClientSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.CheckNewClient || Disabled}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Unique Client ID"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="UniqueClientId"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/SearchForBackOffice"
											Method="POST"
											RenderItem={this.RenderUniqueClientSelect}
											Parameter={this.ParameterClientSearchCS}
											Required
											IsInvalid={vModel.Id}
										/>
									</GridItem>
								)}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.CheckNewClient || Disabled}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Sales Agent Name"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="Name"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/SearchForBackOffice"
											Method="POST"
											RenderItem={this.RenderClientNameSelect}
											Parameter={this.ParameterClientSearchSA}
											Required
											IsInvalid={vModel.Id}
										/>
									</GridItem>
								)}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Disabled={CustomDisabled || paramGlobalModel.CheckNewClient || Disabled}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Unique Client ID"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="UniqueClientId"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/SearchForBackOffice"
											Method="POST"
											RenderItem={this.RenderUniqueClientSelect}
											Parameter={this.ParameterClientSearchSA}
											Required
											IsInvalid={vModel.Id}
										/>
									</GridItem>
								)}
								{/* Client List  */}


								{/* Other Monitoring Menu  */}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode != MenuCodes.Backoffice_SalesAgentSetup && paramGlobalModel.MenuCode != MenuCodes.Backoffice_ClientSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Sorted={{ Member: "Name" }}
											Disabled={(CustomDisabled || Disabled) && !isSalesPerson}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Client Name"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="Name"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											Parameter={this.ParameterUniqueClientId}
											Required
											IsInvalid={vModel.Id}
										/>
									</GridItem>
								)}
								{(paramGlobalModel != null && paramGlobalModel.MenuCode != MenuCodes.Backoffice_SalesAgentSetup && paramGlobalModel.MenuCode != MenuCodes.Backoffice_ClientSetup) && (
									<GridItem xs={3} >
										<GenericSelectInput
											IsTextBold
											Sorted={this.ParameterSortUniqueClientId}
											Disabled={(CustomDisabled || Disabled) && !isSalesPerson}
											key={this.state.IsClientComponent}
											Name="Id"
											LabelText="Unique Client ID"
											Value={paramGlobalModel.Id || ""}
											DataRoot="Item"
											ValueChanged={this.handleChangeGlobal}
											KeyValueMember="Id"
											TextValueMember="UniqueClientId"
											TaskPoolDomain={this.loadTaskPool}
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											Parameter={this.ParameterUniqueClientId}
											Required
											IsInvalid={vModel.Id}

										/>
									</GridItem>
								)}
								{/* Other Monitoring Menu  */}


								{(paramGlobalModel != null && (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || paramGlobalModel.MenuCode == MenuCodes.Backoffice_ClientSetup)) && (
									<GridItem xs={2} style={{ paddingLeft: "5px !important", marginTop: "10px !important", paddingTop: "7px !important" }} >
										<GenericCheckInput
											Disabled={CustomDisabled || Disabled} IsTextBold Name="CheckNewClient" LabelText={this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup ? "New Sales Agent" : "New Client"}
											Value={paramGlobalModel.CheckNewClient || false} ValueChanged={this.handleChangeGlobal} />
									</GridItem>
								)}

								<GridItem xs={1}>

								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<TabsComponent
									TabIndex={this.state.ParentTabIndex}
									handleChange={(e, a) => this.handleChangeParentTab(e, a, true)}
									tabList={[
										{
											tabButton: this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup
												|| (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal) ? "Sales Agent Details" : "Client Details",
											tabContent: (
												<CustomerDetails
													key={"custo_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id}
													paramGlobalModel={this.state.paramGlobalModel}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramBankCustomerAddressList={this.state.paramBankCustomerAddressList}
													handleChangePersonal={this.handleChangePersonal}
													handleChangeCorporate={this.handleChangeCorporate}
													setCustomerState={this.setCustomerState}
													setCorporateState={this.setCorporateState}
													setIdentificationState={this.setIdentificationState}
													paramBankCustomerCorporate={this.state.paramBankCustomerCorporate}
													paramBankCustomerCorporateAuthOwnerList={this.state.paramBankCustomerCorporateAuthOwnerList}
													paramBankCustomerCorporateAuthPersonList={this.state.paramBankCustomerCorporateAuthPersonList}
													paramIdentificationMethot1={this.state.paramIdentificationMethot1}
													paramIdentificationMethot2={this.state.paramIdentificationMethot2}
													handleChangeIdentification1={this.handleChangeIdentification1}
													handleChangeIdentification2={this.handleChangeIdentification2}
													handleChangePhone1={this.handleChangePhone1}
													handleChangePhone2={this.handleChangePhone2}
													handleChangePhone3={this.handleChangePhone3}
													handleChangeCorporatePhoneFax={this.handleChangeCorporatePhoneFax}
													handleChangeCorporatePhoneBuss={this.handleChangeCorporatePhoneBuss}
													isSalesAgentPersonel={paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)}
													paramCustomerMobilePhone={this.state.paramCustomerMobilePhone}
													paramCustomerPrimaryPhone={this.state.paramCustomerPrimaryPhone}
													paramCustomerAlternativePhone={this.state.paramCustomerAlternativePhone}
													paramCorporateBussinesPhone={this.state.paramCorporateBussinesPhone}
													paramCorporateFaxPhone={this.state.paramCorporateFaxPhone}

													GetCorporateProfile={this.GetCorporateProfile}
													GetCustomerAddressModels={this.GetCustomerAddressModels}
													GetCustomerCorporateAuthOwnerModels={this.GetCustomerCorporateAuthOwnerModels}
													GetCustomerCorporateAuthPersonModels={this.GetCustomerCorporateAuthPersonModels}

													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}

													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}

													paramBankCustomerKeyContactList={this.state.paramBankCustomerKeyContactList}
													GetCustomerKeyContactModels={this.GetCustomerKeyContactModels}
													paramBankCustomerDirectorsList={this.state.paramBankCustomerDirectorsList}
													GetCustomerDirectorsModels={this.GetCustomerDirectorsModels}

													paramBankCustomerRegulatoryLicenseList={this.state.paramBankCustomerRegulatoryLicenseList}
													GetCustomerRegulatoryLicenses={this.GetCustomerRegulatoryLicenses}

													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
													MenuCode={this.props.MenuCode}
												/>
											)
										},
										{
											tabButton: paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal
												? "Rate Definition" : "Contract Details",
											tabContent: (
												<ContractDetails
													isSalesAgentPersonel={paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)}
													key={"contract_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramGlobalModel={this.state.paramGlobalModel}
													paramBankCustomerContractList={this.state.paramBankCustomerContractList}
													GetCustomerContractModels={this.GetCustomerContractModels}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													MenuCode={this.props.MenuCode}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													Disabled={this.props.Disabled}
													showMessage={this.props.showMessage}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
												/>
											)
										},
										{
											tabButton: "Settlement",
											tabContent: (
												<Settlement
													key={"settlement_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramGlobalModel={this.state.paramGlobalModel}
													paramBankCustomerInternalAccountList={this.state.paramBankCustomerInternalAccountList}
													paramBankCustomerExternalAccountList={this.state.paramBankCustomerExternalAccountList}
													GetCustomerExternalBankAccountModels={this.GetCustomerExternalBankAccountModels}
													GetCustomerDCBankAccountModels={this.GetCustomerDCBankAccountModels}
													paramBankCustomerContractList={this.state.paramBankCustomerContractList}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													MenuCode={this.props.MenuCode}
													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
													isSalesAgentPersonel={paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)}
												/>
											)
										},
										{
											tabButton: "Security",
											tabContent: (
												<Security
													key={"security_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramGlobalModel={this.state.paramGlobalModel}
													paramBankCustomerInternalAccountList={this.state.paramBankCustomerInternalAccountList}
													paramBankCustomerSecurityList={this.state.paramBankCustomerSecurityList}
													GetCustomerSecurityModels={this.GetCustomerSecurityModels}
													paramBankCustomerContractList={this.state.paramBankCustomerContractList}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
												/>
											),
											Hidden: paramGlobalModel.MenuCode == "" ? false : paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup ? true
												: paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal ? true : false
										},
										{
											tabButton: "Risk Rating",
											tabContent: (
												<RiskRatingTabs
													key={"risk_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id + this.RiskRatingMemoTypeId}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramGlobalModel={this.state.paramGlobalModel}
													paramBankCustomerMemoList={this.state.paramBankCustomerMemoList.filter(x => x.MemoTypeId == this.RiskRatingMemoTypeId)}
													paramBankCustomerKeyContactList={this.state.paramBankCustomerKeyContactList}
													memoTypeId={this.RiskRatingMemoTypeId}
													GetCustomerMemoModels={this.GetCustomerMemoModels}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
												/>
											),
											Hidden: paramGlobalModel.MenuCode == "" ? false :
												paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup
													|| paramGlobalModel.MenuCode == MenuCodes.CallCenterClientMonitor
													|| (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)
													? true : false

										},
										{
											tabButton: this.props.MenuCode == MenuCodes.Backoffice_SalesAgentSetup ||
												(paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)
												? "Sales Agent Summary" : "Client Summary",
											tabContent: (
												<ClientSummary
													key={"summary_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramBankCustomerCorporate={this.state.paramBankCustomerCorporate}
													paramGlobalModel={this.state.paramGlobalModel}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													isSalesAgentPersonel={paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)}
													paramBankCustomerContractList={this.state.paramBankCustomerContractList}
													paramBankCustomerInternalAccountList={this.state.paramBankCustomerInternalAccountList}
													paramBankCustomerExternalAccountList={this.state.paramBankCustomerExternalAccountList}
													paramBankCustomerSecurityList={this.state.paramBankCustomerSecurityList}
													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
													MenuCode={this.props.MenuCode}
												/>
											)
										},
										{
											tabButton: "Memo",
											tabContent: (
												<Memo
													key={"memo_tab_render" + this.state.paramGlobalModel == null ? "0" : this.state.paramGlobalModel.Id + this.ClientMemoTypeId}
													paramCustomerProfile={this.state.paramCustomerProfile}
													paramGlobalModel={this.state.paramGlobalModel}
													paramBankCustomerMemoList={this.state.paramBankCustomerMemoList.filter(x => x.MemoTypeId == this.ClientMemoTypeId)}
													memoTypeId={this.ClientMemoTypeId}
													GetCustomerMemoModels={this.GetCustomerMemoModels}
													hideSidebar={this.props.hideSidebar}
													setAppLeftTitle={this.props.setAppLeftTitle}
													setAppCenterTitle={this.props.setAppCenterTitle}
													isSalesAgentPersonel={paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesAgentSetup || (paramGlobalModel.MenuCode == MenuCodes.Backoffice_SalesPersonClientMonitoring && paramGlobalModel.ClientType == ClientType.SalesAgentPersonal)}
													handleChangeParentTab={this.handleChangeParentTab}
													handleChangeChildTab={this.handleChangeChildTab}
													ChildTabIndex={this.state.ChildTabIndex}
													Disabled={this.props.Disabled}
													FetchClientLimitedAuthority={this.FetchClientLimitedAuthority}
													MenuCode={this.props.MenuCode}
												/>
											)
										}
									]}
								/>
							</GridItem>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

ClientSetup.propTypes = {
	classes: PropTypes.object,
	hideSidebar: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(ClientSetup, {});