import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, GridColumnType, IdentificationMethod, MenuCodes } from "views/Constants/Constant";
import CanadaPostAddressComponent from "../../../../Components/CanadaPostAddressComponent";

class CorporateAuthPerson extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			isZipCodeOptional: false,
			corporateAuthPersonModel: {},
			address: {},
			phoneModel: {},
			isLoading: false,
			alert: null,
			CustomDisabled: null
		};
		this.ParameterTitle = { ParameterCode: "Title" };
		this.ParameterEmptyObject = {};
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.ParameterMaxLength15 = { maxLength: 15 };
		this.ParameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.ParameterIdentityType = { ParameterCode: "IdentityType" };
		this.ParameterIdentityVerifyingPerson = { ParameterCode: "IdentityVerifyingPerson" };
		this.sortedParameterDesc = { Member: "ParameterDesc" };
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ corporateAuthPersonModel: { ...row.original }, phoneModel: { ...row.original.Phone } })} />

						<GridButton
							tooltip="Delete"
							Icon={this.state.CustomDisabled || DeleteIcon}
							Disabled={props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Name",
				accessor: "Name",
				Cell: row => (
					<div>
						<span>
							{
								row.original.FirstName + " " + row.original.MiddleName + " " + row.original.LastName
							}
						</span>
					</div>
				)
			},
			{
				Header: "Title",
				accessor: "Title.ParameterDesc"
			},
			{
				Header: "Phone",
				accessor: "Phone.PhoneNumber",
				Cell: row => (
					<div>
						<span>
							{
								"+" + row.original.Phone.PhoneCountryCode.Code + " " + row.original.Phone.PhoneNumber
							}
						</span>
					</div>
				)
			},
			{
				Header: "E-Mail",
				accessor: "Email"
			},
			{
				Header: "Identification Method",
				accessor: "IdentificationMethod.ParameterDesc"
			},
			{
				Header: "Identification Type",
				accessor: "IdentityType.ParameterDesc"
			},
			{
				Header: "Verifying Person",
				accessor: "VerifyingPerson.ParameterDesc"
			},
			{
				Header: "Identification No.",
				accessor: "IdentityNumber",
				Cell: row => (
					props.paramGlobalModel.IsExceptClientSetup == true ? this.FilterIdentityNumber(row.value) : row.value
				)
			},
			{
				Header: "ID Place of Issue",
				accessor: "IdentityPlaceOfIssue"
			},
			{
				Header: "Identity Expire Date",
				accessor: "IdentityExpireDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Address line 1",
				accessor: "CustomerCAPersonAddress.Address1"
			},
			{
				Header: "Address line 2",
				accessor: "CustomerCAPersonAddress.Address2"
			},
			{
				Header: "Country",
				accessor: "CustomerCAPersonAddress.Country.Name",
				sortable: false
			},
			{
				Header: "Province",
				accessor: "CustomerCAPersonAddress.Province.Name",
				sortable: false
			},
			{
				Header: "City",
				accessor: "CustomerCAPersonAddress.City.Name",
				sortable: false
			},
			{
				Header: "Postal Code",
				accessor: "CustomerCAPersonAddress.ZipCode",
				sortable: false
			}
		];
	}
	RenderCountrySelect(d) {
		return "+" + d.Code + " (" + d.Name + ")";
	}
	componentDidMount() {

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a customer or create new one to add corporate auth person.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ corporateAuthPersonModel: model, address: model.CustomerCAPersonAddress  });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedSigningOfficer, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}

	OperationCheck = () => {
		var ErrorList = [];
		const global = { ...this.props.paramGlobalModel };
		const model = this.state.corporateAuthPersonModel;
		const phoneModel = this.state.phoneModel;
		const vModel = this.state.vModel;
		const Address = this.state.address;

		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a customer or create new one to add.");
		}

		if (model == null) {
			return;
		}
		if (model.FirstName == null || model.FirstName == "") {
			vModel.FirstName = true;
			ErrorList.push("FirstName must be defined !.");
		} else { vModel.FirstName = false; }
		if (model.MiddleName == null || model.MiddleName == "") {
			vModel.MiddleName = true;
			ErrorList.push("MiddleName must be defined !.");
		} else { vModel.MiddleName = false; }
		if (model.LastName == null || model.LastName == "") {
			vModel.LastName = true;
			ErrorList.push("LastName must be defined !.");
		} else { vModel.LastName = false; }
		if (Address.Address1 == null || Address.Address1 == "") {
			vModel.Address1 = true;
			ErrorList.push("Address1 must be defined !.");
		} else { vModel.Address1 = false; }

		if (Address.CountryId == null || Address.CountryId == "") {
			vModel.CountryId = true;
			ErrorList.push("Country must be defined !.");
		} else { vModel.CountryId = false; }

		if ((Address.ProvinceId == null || Address.ProvinceId == "" || model.ProvinceId < 1) && (model.NewProvince == null || model.NewProvince == "")) {
			vModel.ProvinceId = true;
			ErrorList.push("Province must be defined !.");
		} else { vModel.ProvinceId = false; }

		if ((Address.CityId == null || Address.CityId == "" || Address.CityId < 1) && (model.NewCity == null || model.NewCity == "")) {
			vModel.CityId = true;
			ErrorList.push("City must be defined !.");
		} else { vModel.CityId = false; }
		if (model.Email == null || model.Email == "") {
			vModel.Email = true;
			ErrorList.push("Email must be defined !.");
		} else { vModel.Email = false };
		if (model.TitleId == null || model.TitleId == "") {
			vModel.TitleId = true;
			ErrorList.push("Title must be defined !.");
		} else { vModel.TitleId = false; }

		if (phoneModel.PhoneCountryCodeId == null || phoneModel.PhoneCountryCodeId == "") {
			vModel.PhoneCountryCodeId = true;
			ErrorList.push("Phone Country Code must be defined !.");

		} else { vModel.PhoneCountryCodeId = false; }

		if (phoneModel.PhoneNumber != null) {
			if (phoneModel.PhoneNumber.toString().length > 10) {
				ErrorList.push("Phone Number must not be greater than 10 Digits !.");
			}
		}
		if (phoneModel.PhoneNumber == null || phoneModel.PhoneNumber == "") {
			vModel.PhoneNumber = true;
			ErrorList.push("Phone Number must be defined !.");
		} else { vModel.PhoneNumber = false; }

		if (model.IdentificationMethodId == null || model.IdentificationMethodId == "") {
			vModel.IdentificationMethodId = true;
			ErrorList.push("Identification Method must be defined !");
		} else { vModel.IdentificationMethodId = false; }

		if ((model.IdentificationMethodId != null && model.IsIdVerification) && (model.VerifyingPersonId == null || model.VerifyingPersonId == "")) {
			vModel.VerifyingPersonId = true;
			ErrorList.push("Verifying Person  must be defined !.");
		} else { vModel.VerifyingPersonId = false; }

		if (model.IdentityTypeId == null || model.IdentityTypeId == "") {
			vModel.IdentityTypeId = true;
			ErrorList.push("Identity Type must be defined !.");
		} else { vModel.IdentityTypeId = false; }

		if (model.IdentityNumber == null || model.IdentityNumber == "") {
			if (model.IdentityTypeId != null) {
				vModel.IdentityNumber = true;
				ErrorList.push("Identity Number must be defined !.");
			}
		} else { vModel.IdentityNumber = false; }

		if (model.IdentityPlaceOfIssue == null || model.IdentityPlaceOfIssue == "") {
			vModel.IdentityPlaceOfIssue = true;
			ErrorList.push("ID Place of Issue must be defined !.");
		} else { vModel.IdentityPlaceOfIssue = false; }

		if (model.Email != null && !this.ValidateEmail(model.Email)) {
			ErrorList.push("E-mail must be valid !");
		}

		if (model.IdentityExpireDate == null || model.IdentityExpireDate == "") {
			vModel.IdentityExpireDate = true;
			ErrorList.push("ID Exp Date must be defined !.");
		} else {
			vModel.IdentityExpireDate = false;
			model.IdentityExpireDate = DateHelper.ToMoment(model.IdentityExpireDate);
			if (!model.IdentityExpireDate.isValid()) {
				ErrorList.push("Identity Expire Date must be valid !.");
			}
			var interval2 = model.IdentityExpireDate.diff(DateHelper.GetDate(), "days");
			if (interval2 < 30) {
				ErrorList.push("Identity Expire Date must be valid In next 30 days !");
			}
		}



		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if ((model.NewCity != null && model.NewCity != "") || (model.NewProvince != null && model.NewProvince != "" && model.NewCity != null && model.NewCity != "")) {
			this.InsertCanadaPost()
		} else {
			this.UpdateModel();
		}
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	FilterIdentityNumber = (number) => {
		if (number == null) return "";

		var regexp = new RegExp("(.*)(.{4})");
		var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
		var sonEk = regexp.exec(number)[2];

		return onEk + sonEk;
	}
	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	handleChangePhone = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.phoneModel || {};
			model[name] = newValue;
			return { phoneModel: model };
		});
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.corporateAuthPersonModel || {};
			temp[name] = newValue;
			if (name == "IdentificationMethodId" && data != null) {
				if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
					temp.IsIdVerification = true;
				} else {
					temp.IsIdVerification = false;
					temp.IdentificationMethod = null;
					temp.VerifyingPersonId = null;
				}
			} else if (name == "IdentityTypeId") {
				temp.IdentityNumber = null;
			}
			return { corporateAuthPersonModel: temp };
		});
	}

	HandleChangeAddress = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.address || {};

			if (name == "CountryId") {
				temp.ProvinceId = 0;
				temp.CityId = 0;
			}
			if (name == "ProvinceId") {
				temp.CityId = 0;
			}

			temp[name] = value;
			return { address: temp };
		});
	}
	
	InsertCanadaPost = () => {

		const model = this.state.corporateAuthOwnerModel;
		let insertModel = {
			CountryId: model.CountryId,
			ProvinceId: model.ProvinceId,
			ProvinceName: model.NewProvince,
			City: model.NewCity
		};
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			insertModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Canada Post Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item)
					this.setState(function (state, props) {
						var temp = state.corporateAuthOwnerModel || {};
						temp.ProvinceId = responseData.Item.ProvinceId;
						temp.CityId = responseData.Item.CityId;
						return { corporateAuthOwnerModel: temp };
					}, this.UpdateModel);

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Canada Post Error", error);
			}
		);
	}
	UpdateModel = () => {
		var temp = this.state.corporateAuthPersonModel;
        temp.CustomerCAPersonAddress = this.state.address;
        const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;
		temp.Phone = this.state.phoneModel;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerCorporateAuthPerson/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Authorized Signing Officer Updated ! " : "Authorized Signing Officer Saved ! ");
				this.ClearData();
				this.props.GetCustomerCorporateAuthPersonModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client address must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerCorporateAuthPerson/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Authorized Signing Officer deleted !");
				this.ClearData();
				this.props.GetCustomerCorporateAuthPersonModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerCorporateAuthPersonList[index];
		this.setState({ corporateAuthPersonModel: model, phoneModel: model.Phone, address: model.CustomerCAPersonAddress });
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ corporateAuthPersonModel: model, phoneModel: model, address: {}, vModel: {} });
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { Disabled: DisabledProp, paramGlobalModel, paramBankCustomerCorporateAuthPersonList } = this.props;
		const { corporateAuthPersonModel, phoneModel, vModel, address } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridContainer spacing={16}>
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="FirstName"
										LabelText="First Name"
										Value={corporateAuthPersonModel.FirstName}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.FirstName}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="MiddleName"
										LabelText="Middle Name"
										Value={corporateAuthPersonModel.MiddleName}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.MiddleName}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="LastName"
										LabelText="Last Name"
										Value={corporateAuthPersonModel.LastName}
										ValueChanged={this.handleChange} 
										Required
										IsInvalid={vModel.LastName}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										Name="TitleId"
										LabelText="Title"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterTitle}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={corporateAuthPersonModel.TitleId}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.TitleId}
									/>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												Disabled={Disabled}
												Name="PhoneCountryCodeId"
												LabelText={"Phone Number"}
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Code"
												Value={phoneModel.PhoneCountryCodeId}
												ValueChanged={this.handleChangePhone}
												Sorted={this.ParameterCountrySortOrderIndex}
												RenderItem={this.RenderCountrySelect}
												Required
												IsInvalid={vModel.PhoneCountryCodeId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Format={"(###) ### ####"}
												LabelMd={0}
												Name="PhoneNumber"
												Value={phoneModel.PhoneNumber}
												ValueChanged={this.handleChangePhone}
												Required
												IsInvalid={vModel.PhoneNumber}
											/>
										</GridItem>
									</GridContainer>
									<GenericEmailInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength64}
										Name="Email"
										LabelText="E-Mail"
										Value={corporateAuthPersonModel.Email}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.Email}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="CUID"
										LabelText="CUID"
										Value={corporateAuthPersonModel.CUID}
										ValueChanged={this.handleChange}
										IsInvalid={vModel.CUID} />
									<GenericDateInput
										Utc
										Disabled={Disabled}
										IsPast={true}
										Name="DateOfBirth"
										LabelText="Date Of Birth"
										Value={corporateAuthPersonModel.DateOfBirth == null ? "" : DateHelper.ToDateInputValue(corporateAuthPersonModel.DateOfBirth)}
										ValueChanged={this.handleChange} />

									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="Occupation"
										LabelText="Occupation"
										Value={corporateAuthPersonModel.Occupation}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.Occupation} />
								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput
										Disabled={Disabled}
										Name="IdentificationMethodId"
										LabelText="Identification Method"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIdentificationMethod}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={corporateAuthPersonModel.IdentificationMethodId}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.IdentificationMethodId}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										Name="IdentityTypeId"
										LabelText="Identification Type"
										Method="GET"
										Url="/bankapi/v1.0/CustomerCorporateAuthOwner/GetIdentityType"
										Parameter={this.ParameterIdentityType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Sorted={this.sortedParameterDesc}
										Value={corporateAuthPersonModel.IdentityTypeId}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.IdentityTypeId}

									/>
									{(paramGlobalModel.IsExceptClientSetup == true) ? (
										<GenericTextInput
											Disabled={true}
											Name="IdentityNumber"
											LabelText="Identification No."
											Value={this.FilterIdentityNumber(corporateAuthPersonModel.IdentityNumber)}
											ValueChanged={this.handleChange}
											Required={corporateAuthPersonModel.IdentityTypeId != null ? true : false}
											IsInvalid={vModel.IdentityNumber}
										/>
									) : (
											<GenericTextInput
												Disabled={Disabled}
												inputProps={this.ParameterMaxLength15}
												Name="IdentityNumber"
												LabelText="Identification No."
												Value={corporateAuthPersonModel.IdentityNumber}
												ValueChanged={this.handleChange}
												Required={corporateAuthPersonModel.IdentityTypeId != null ? true : false}
												IsInvalid={vModel.IdentityNumber}
											/>
										)}

									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength15}
										Name="IdentityPlaceOfIssue"
										LabelText="ID Country of Issue"
										Value={corporateAuthPersonModel.IdentityPlaceOfIssue}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.IdentityPlaceOfIssue}
									/>
									
									<GenericTextInput
										inputProps={{ maxLength: 15 }}
										Name="IdentityProvinceOfIssue"
										LabelText="ID Province/State of Issue"
										Value={corporateAuthPersonModel.IdentityProvinceOfIssue}
										ValueChanged={this.handleChange}
										Required={corporateAuthPersonModel.IdentityPlaceOfIssue != null && (corporateAuthPersonModel.IdentityPlaceOfIssue.toUpperCase() === "CAD" || corporateAuthPersonModel.IdentityPlaceOfIssue.toUpperCase() === "CAN"|| corporateAuthPersonModel.IdentityPlaceOfIssue.toUpperCase() === "USA" ||
											corporateAuthPersonModel.IdentityPlaceOfIssue.toUpperCase() === "CANADA" || corporateAuthPersonModel.IdentityPlaceOfIssue.toUpperCase() === "UNITED STATES OF AMERICA")}
									/>
									<GenericDateInput
										Utc
										Disabled={Disabled}
										IsFuture={true}
										Name="IdentityExpireDate"
										LabelText="ID Exp Date"
										Value={corporateAuthPersonModel.IdentityExpireDate == null ? "" : DateHelper.ToDateInputValue(corporateAuthPersonModel.IdentityExpireDate)}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.IdentityExpireDate}
									/>

									{((corporateAuthPersonModel.IsIdVerification) || (corporateAuthPersonModel.IdentificationMethod != null && corporateAuthPersonModel.IdentificationMethod.ParameterValue == IdentificationMethod.InPersonIDVerification)) && (
										<GenericSelectInput
											Disabled={Disabled}
											Name="VerifyingPersonId"
											LabelText="Verifying Person"
											Value={corporateAuthPersonModel.VerifyingPersonId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterIdentityVerifyingPerson}
											DataRoot="Item"
											Required
											IsInvalid={vModel.VerifyingPersonId}
										/>
									)}
								</GridItem>
								<GridItem xs={4}>
									<CanadaPostAddressComponent
										key={this.state.corporateAuthPersonModel?.Id + "CanadaPostAddressComponent"}
										isZipCodeOptional={this.state.isZipCodeOptional}
										vModel={this.state.vModel}
										GridXs={4}
										GridCount={0}
										addressModel={address || {}}
										Disabled={Disabled}
										handleChange={this.HandleChangeAddress}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{corporateAuthPersonModel.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericGrid
										Data={paramBankCustomerCorporateAuthPersonList}
										PageSize={5}
										HideButton={true}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}
CorporateAuthPerson.propTypes = {
	classes: PropTypes.object,
	addressModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerAddressList: PropTypes.array,
	GetCustomerAddressModels: PropTypes.func,
	paramBankCustomerStoreList: PropTypes.array,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func
};
export default withArtifex(CorporateAuthPerson, {});